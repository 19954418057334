import React, { useContext, useEffect, useState } from 'react'
import Banner from '../../Public/Home/Banner'
import { Box, Modal } from '@mui/material';
import { v1Basic } from '../../../Resource/api';
import axios from 'axios';
import Loading from '../../../Components/Loading';
import { AppContext } from '../../../Context/AppContext';
import { Navigate } from 'react-router-dom';

export default function ListOfBanner() {
  const{token,role} = useContext(AppContext)
  const [banner, setBanner] = useState([]);
  const [apiResponse, setApiRespnse] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bannerForm, setBannerForm] = useState([])
  const [loading, setLoading] = useState(true)
  const [counter, setCounter] = useState(true)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const handleModal = () => setModalIsOpen(!modalIsOpen)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(isSubmitting) return
    setIsSubmitting(true)
    console.log('submiting')
    const formData = new FormData();
    try{
      for (const key in bannerForm) {
        formData.append(`${key}`, bannerForm[key]);
      }
      
      const response = await axios.post(v1Basic.concat('banner'), bannerForm, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      handleModal()
      
    } catch (error) {
      if (error.response) {
        setApiRespnse(error.response.data.errors)
        console.error('Error Response Data:', error.response.data);
        console.error('Error Response Status:', error.response.status);
        console.error('Data:', bannerForm);
      } else if (error.request) {
        console.error('Error Request:', error.request);
      } else {
        console.error('Error Message:', error.message);
      }
    } finally {
      setIsSubmitting(false)
    }
    setCounter(!counter)
  }

  const handleChange = (e) => {
    const {name, value, files} = e.target
    if(name == 'image'){
      const file = files[0]
      setBannerForm({
        ...bannerForm,
        [name]: file
      })
    }else{
      setBannerForm({
        ...bannerForm,
        [name]: value
      })
    }
  }

  useEffect(() => { 
    const fetchData = async () => {
      setLoading(true);
      try {
        const bannerResponse = await axios.get(v1Basic.concat('banner'))
        
        setBanner(bannerResponse.data.data)
      } catch (error) {
          console.error("Error fetching data:", error)
      } finally {
          setLoading(false)
      }
    };

    fetchData();
  }, [counter]);

  const [searchData, setSearchData]= useState({
    type: 'banner',
    term: '',
    team_id: null,
    college_id: null,
  })

  const handleSearch = async () => {
    try{
      setLoading(true)
      const response = await axios.put(v1Basic.concat('search'),searchData, {
        headers: {
          'Content-type': 'application/json'
        }
      })

        setBanner(response.data.data)
      // console.log(response)
    } catch (error) {
      console.error(error)
    }finally {
      setLoading(false)
    }
  }

  const handleTerm = (e) => {
    const {name, value} =e.target
    setSearchData({
      ...searchData,
      [name]: value
    })
  } 
  return (
    <>    
    {token==null && (<Navigate to={'/login'} replace={true}/>)}
    {role != 'Admin' && (<Navigate to={'/'} replace={true}/>)}

    {loading && (<Loading />)}
    <>
      <Modal open={modalIsOpen} onClose={handleModal}
        aria-labelledby="modal-title" aria-describedby="modal-description"
        className="flex justify-center items-center h-screen"
      >
        <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
          {loading && (<Loading />)}

          <h3 id="modal-title" className='font-semibold text-xl'>New Banner</h3>
          <p id="modal-description " className='mb-4'>This Popup is for adding a new Banner.</p>
          <form onSubmit={handleSubmit}>
            <label className='text-sm'>Select Image</label>
            <br></br>
            <input type="file" name='image' onChange={handleChange} accept="image/png, image/jpg, image/jpeg" className=' w-full rounded-md p-2 bg-def-t bg-opacity-5 cursor-pointer'/>
            {apiResponse?.image &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.image}</div>)}
            <label className='text-sm'>Banner Title</label>
            <br></br>
            <input type='text' placeholder='Ex. Intramurals, Foundation'
                    name='title' onChange={handleChange}
                    className=' w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
            {apiResponse?.title &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.title}</div>)}

            <label className='text-sm'>Year</label>
            <br></br>
            <input  type='number' placeholder='Ex. 2024'
            name='year' onChange={handleChange}
            className=' w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
            {apiResponse?.year &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.year}</div>)}

            <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Add Banner</button>
          </form>
        </Box>
      </Modal>
      <div className='bg-cont flex-col flex m-2 p-3 rounded-lg'>
          <div className='flex-1 mb-8'>
            <div className='flex'>
              <div className=' flex-1 '>
                <div className=' flex'>
                  <input name='term' onKeyDown={(e) => e.key === 'Enter' && handleSearch(e)} onChange={handleTerm} type='text' placeholder={`Find Banner`} 
                        className='bg-transparent p-2 border-2 rounded-l-md flex-1' />
                  <div className='p-3 flex-none bg-prc rounded-r-md'>
                    <span className='icon-[majesticons--search] bg-white w-5 h-5 mt-2 mx-2' onClick={handleSearch}></span>
                  </div>
                  <div className=' px-10 ml-20 text-center content-center flex-none cursor-pointer bg-prc text-white rounded-md' onClick={handleModal}>
                    Add Banner
                  </div>
                </div>
              </div>
            </div>
              <div className=' mt-10'>
                {banner.map((item, index) => (
                  <Banner key={index} data={item}/>
                ))}
              </div>
          </div>
      </div>
      </>

    </>
  )
}
