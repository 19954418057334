import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { router } from './Resource/api';
import AppProvider from './Context/AppContext';


// Render the application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider>
      <RouterProvider router={router} />
  </AppProvider>
);

// Measure performance (optional)
reportWebVitals();
