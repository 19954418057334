import React from 'react'
import { MatchSchedule } from '../../../Components/Cards'
import { formatDate } from '../../Admin/Schedule/Bracketing'

export default function Schedule({ data }) {
  const sortedResponse = sortedByTime(data);

  // Filter out items where either firstTeam or secondTeam is null
  const filteredResponse = sortedResponse.filter(
    (item) => item.firstTeam !== null && item.secondTeam !== null
  );

  if (filteredResponse.length === 0) {
    return null; // Return nothing if no valid items
  }

  return (
    <div className='bg-cont mt-3 rounded-r-md p-3'>
      <h1 className='text-2xl font-bold text-prc mb-3'>{formatDate(data[0].date)}</h1>
      {filteredResponse.map((item, index) => (
          <MatchSchedule key={index} data={item} />
          
      ))}
    </div>
  );
}

export function sortedByTime(data) {
  const sortedResponse = data.sort((a, b) => {
    const dateA = new Date(`${a.date}T${a.time}`);
    const dateB = new Date(`${b.date}T${b.time}`);

    return dateA - dateB;
  });

  return sortedResponse;
}