import { Box, Modal } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { v1Basic } from '../../../Resource/api'
import Loading from '../../../Components/Loading'

export default function Banner({data}) {
  const [isAdmin, setIsAdmin] = useState(false)
  const [isDefault,setIsDefault] = useState(false)
  const [loading,setLoading] = useState(false)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const handleModal = () => setModalIsOpen(!modalIsOpen)

  const [deleteModal, setDeleteModal] = useState(false)
  const handleDeleteModal = () => setDeleteModal(!deleteModal)

  useEffect(() => {
    const loc = window.location.href
    const string ='admin/banner'
    if(loc.toLowerCase().includes(string.toLowerCase())){
      setIsAdmin(true)
    }else(
      setIsAdmin(false)
    )
    if(data.isDefault == 1){
      setIsDefault(true)
    }
  }, [data])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try{
      const response = await axios.put(v1Basic.concat(`banner/${data.id}`), data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('Response:', response);
      window.location.reload()
      handleModal()
    } catch(error){
      console.log(error)
    } finally {
    setLoading(false)

    }
  }

  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true)

    try{
      const response = await axios.delete(v1Basic.concat(`banner/${data.id}`), data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      handleDeleteModal()
      window.location.reload()
      console.log(response)
    } catch(error){
      console.log(error)
    } finally {
      setLoading(false)
  
    }

  }

  return (
    <>
    {isAdmin ? (
    <>
      <Modal open={modalIsOpen} onClose={handleModal}
          aria-labelledby="modal-title" aria-describedby="modal-description"
          className="flex justify-center items-center h-screen"
      >
          <Box className="bg-white rounded-lg shadow-lg text-def-t ">
            {loading && <Loading />}
              <h3 id="modal-title" className='font-semibold text-xl text-center bg-prc rounded-t-lg text-white py-3'>Default Banner</h3>
              <div className='p-6'>
                  <p id="modal-description " className='mb-4'>Are you sure you want to set this as Default Banner </p>
                  <form onSubmit={handleSubmit} className='flex'>
                      <div className='flex-1 flex  pr-10 mt-2  rounded-md py-2 '>
                        <div className='flex-1'></div>
                        <div className='flex-none cursor-pointer underline w-auto  text-prc hover:scale-101' onClick={handleModal}>
                           Cancel
                          </div>
                      </div>
                      <button type='submit' className='flex-1 mt-2 bg-prc rounded-md py-2 w-full text-white'>Yes</button>
                  </form>
              </div>
          </Box>
      </Modal>

      <Modal open={deleteModal} onClose={handleDeleteModal}
          aria-labelledby="modal-title" aria-describedby="modal-description"
          className="flex justify-center items-center h-screen"
      >
          <Box className="bg-white rounded-lg shadow-lg text-def-t ">
          {loading && <Loading />}

              <h3 id="modal-title" className='font-semibold text-xl text-center bg-prc rounded-t-lg text-white py-3'>Delete Banner</h3>
              <div className='p-6'>
                  <p id="modal-description " className='mb-4'>Are you sure you want to Delete this banner? </p>
                  <form onSubmit={handleDelete} className='flex'>
                      <div className='flex-1 flex  pr-10 mt-2  rounded-md py-2 '>
                        <div className='flex-1'></div>
                        <div className='flex-none cursor-pointer underline w-auto  text-prc hover:scale-101' onClick={handleDeleteModal}>
                           Cancel
                          </div>
                      </div>
                      <button type='submit' className='flex-1 mt-2 bg-prc rounded-md py-2 w-full text-white'>Yes</button>
                  </form>
              </div>
          </Box>
      </Modal>
      <div className='flex'>
        <div className='flex-1'></div>
        {data?.isDefault === 0 && 
        <div onClick={handleDeleteModal} className='text-red-700 flex-none hover:underline text-sm cursor-pointer'>
          Delete Banner
        </div>
        }
      </div>
      <div className='relative mb-4 drop-shadow-sm cursor-pointer ' onClick={handleModal}>
        <img src={data.image != null ? (data.image) : (require('../../../Resource/banner-dummy.jpg'))} 
          className={`object-cover h-64 w-full ${ data?.isDefault === 1 && ('border-y-4 border-prc rounded-xl')}`}/>
        <div className='absolute inset-0 flex flex-col justify-center font-extrabold text-6xl bg-black bg-opacity-10 text-white drop-shadow-md pl-3'>
            <div className=''>
                {data.title == null ? (<div>No Data</div>):(data.title)}
            </div>
            <div>
              {data.year == null ? (<div>No year</div>):(data.year)}
            </div>
        </div>
      </div>
    </>
    ) : (
      <div className='relative mb-4 drop-shadow-sm'>
        <img src={data.image != null ? (data.image) : (require('../../../Resource/banner-dummy.jpg'))} className='object-cover h-64 w-full'/>
        <div className='absolute inset-0 flex flex-col justify-center font-extrabold text-6xl bg-black bg-opacity-10 text-white drop-shadow-md pl-3'>
            <div className=''>
                {data.title == null ? (<div>No Data</div>):(data.title)}
            </div>
            <div>
              {data.year == null ? (<div>No year</div>):(data.year)}
            </div>
        </div>
      </div>
    )}
    </>
  )
}
