import React, { useContext, useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart' 
import { BasicTitleAndSub, CRUD } from '../../../Components/Cards'
import { BasicTeams, PlayerInformation } from '../MasterFile/Team'
import { baseUrl, v1Basic } from '../../../Resource/api'
import axios from 'axios'
import Loading from '../../../Components/Loading'
import { AppContext } from '../../../Context/AppContext'
import { Navigate, Outlet } from 'react-router-dom'

export default function Dashboard() {
    const [college, setCollege] = useState([]);
    const [sport, setSports] = useState([]);
    const [team, setTeam] = useState([]);
    const [tally, setTally] = useState([]);
    const [acronym, setAcronym] = useState([])
    const [gold,setGold] = useState([])
    const [silver, setSilver] = useState([])
    const [bronze, setBronze] = useState([])
    const [loading, setLoading] = useState(true);
    const {token} = useContext(AppContext)
    const apiCollege = v1Basic.concat("college")
    const apiSport = v1Basic.concat("sports")
    const apiTeam = v1Basic.concat("team")

    const handleChange = async (id, e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const teamResponse = await axios.get(apiSport.concat(`/${id}`));
            setTeam(teamResponse.data.data.team);
        } catch(error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    } 

    useEffect(() => { 
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch data from all endpoints
                const teamResponse = await axios.get(apiTeam);
                const sportsResponse = await axios.get(apiSport);
                const collegeResponse = await axios.get(apiCollege);
                const tallyResponse = await axios.get(`${baseUrl}/v1/compute/tally`);
    
                // Update states with fetched data
                setTeam(teamResponse.data.data);
                setSports(sportsResponse.data.data);
                setCollege(collegeResponse.data.data);
                setTally(tallyResponse.data.data);
    
                // Process tally data
                const goldData = tallyResponse.data.data.map((item) => item.gold);
                const silverData = tallyResponse.data.data.map((item) => item.silver);
                const bronzeData = tallyResponse.data.data.map((item) => item.bronze);
                const acronymData = tallyResponse.data.data.map((item) => item.acronym);
    
                // Set processed data into state
                setGold(goldData);
                setSilver(silverData);
                setBronze(bronzeData);
                setAcronym(acronymData);
    
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); 
            }
        };
    
        fetchData();
    }, []); // Empty dependency array ensures this runs only once on mount
    
    // console.log(acronym)
    return (
        <>
            {token==null && (<Navigate to={'/login'} replace={true}/>)}

        {loading ? ( // Display loading indicator while data is loading
            <Loading />
        ) : (
        <>
        <div className='flex m-2'>
        <div className=' flex-1 bg-cont p-3 rounded-md'>
            <div className=' text-2xl font-bold text-prc mb-2'>
                Medal Tally
            </div>
            <div className=' bg-white rounded-sm py-4'>
                <BarChart
                    series={[
                        { label: 'Gold', data: gold, color: 'gold' }, // gold
                        { label: 'Silver', data: silver, color: 'silver' },
                        { label: 'Bronze', data: bronze, color: 'brown' } // silver
                        // { label: 'Bronze', data: [15, 25, 30, 50,35, 44, 24, 34], color: 'brown' }, // bronze
                    ]}
                    height={400}
                    xAxis={[{ data: acronym, scaleType: 'band' }]}
                    margin={{ top: 10, bottom: 30, left: 30, right: 10 }}
                    // tooltip
                />
            </div>

        </div>
        <div className=' flex-none bg-cont rounded-md ml-2 p-4 '>
            <div className=' text-2xl font-bold text-prc mb-2'>
                Colleges
                <div className=' overflow-auto h-[440px] overflow-x-hidden'>
                    {college.map((item, index) => (

                        <BasicTitleAndSub data={item} key={index}/>
                    ))}
                    
                </div>
            </div>
        </div>
        </div>
        <div className=' flex mb-4'>

            <div className='flex-none mx-2 p-3 bg-cont rounded-md'>
                <div className=' text-2xl font-bold text-prc mb-2'>
                    Sports
                    <div className=' overflow-auto h-[440px] overflow-x-hidden'>
                    {sport.map((item, index) => (
                        <div key={index} onClick={(e) => handleChange(item?.id, e)}>
                            <BasicTitleAndSub data={item}/>
                        </div>
                    ))}

                    </div>
                </div>
            </div>

            <div className='flex-1 mr-2  bg-cont rounded-md'>
                <div className='  font-bold text-prc mb-2'>
                    <div className=' text-2xl p-3'>Teams</div>
                    <div className=' overflow-auto h-[440px] overflow-x-hidden w-full'>
                        <table className=' w-full'>
                            <thead className='bg-prc text-white text-left'>
                                <tr>

                                    <th className='px-6 py-3'>Team Name</th>
                                    <th className='px-6 py-3'>Sport</th>
                                    <th className='px-6 py-3'>Coach</th>
                                    <th className='px-6 py-3'>No. of Players</th>
                                </tr>
                            </thead>

                            <tbody>
                            
                            {team.map((item, index) => (

                                <BasicTeams data={item} key={index}/>
                            ))}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </>
        )}
        </>
    )
}

export function DashboardIndex(){
    return (
        <>
        <Outlet/>
        </>
    )
}
