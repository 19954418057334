import React, { useContext, useEffect, useState } from 'react';
import { SingleEliminationBracket, Match } from '@g-loot/react-tournament-brackets';
import { useWindowSize } from 'react-use';
import { createTheme } from '@g-loot/react-tournament-brackets/dist/cjs/themes/themes';
import SvgViewer from '@g-loot/react-tournament-brackets/dist/cjs/svg-viewer';
import { Navigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl, v1Basic } from '../../../Resource/api';
import { Box, Modal } from '@mui/material';
import { AppContext } from '../../../Context/AppContext';

export default function Bracketing() {
  const { sportID } = useParams();
  const {token,role} = useContext(AppContext)
  const [sports, setSports] = useState({});
  const [loading, setLoading] = useState(true);
  const [noSchedule, setNoSchedule] = useState(true); // Track if there's no schedule
  const [teamArrangement, setTeamArrangement] = useState({
    sports: {
      id: sportID,
    },
    team: {}
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleModal = () => {
    if (warningIsVisible) {
      handleWarning();
    }
    setModalIsOpen(!modalIsOpen);
  };

  const [warningIsVisible, setWarningIsVissible] = useState(false);
  const handleWarning = () => setWarningIsVissible(!warningIsVisible);

  const teams = sports?.team || [];
  console.log(teams)
  const generateHandle = async (e) => {
    e.preventDefault();
    e.target.disabled = true
    console.log("hehe")
    const shuffledTeams = shuffleTeams(teams);
    const teamArrangement = {
      team: {},
      sports: {
        id: sportID,
        num_of_teams: teams.length
      }
    }

    shuffledTeams.forEach((team, index) => {
      teamArrangement.team[`team-id-${index}`] = team.id;
    });

    const team = teamArrangement?.team || [];
    const dataIsRepeated = hasDuplicates(Object.values(team));

    if (dataIsRepeated) {
      if (!warningIsVisible) {
        handleWarning();
      }
      console.log(teamArrangement);
    } else {
      try{
        const response = await axios.post(baseUrl.concat('/v1/batch-schedule'), teamArrangement, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log(response)

      } catch(e){
        console.error(e)
      } finally {
        window.location.reload()
        e.target.disabled = false

      }
    }
  };

  const hasDuplicates = (arr) => new Set(arr).size !== arr.length;

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(teamArrangement)
    setTeamArrangement((prevForm) => ({
      ...prevForm,
      team: {
        ...prevForm.team,
        [name]: value
      },
      sports: {
        id: sportID,
        num_of_teams: teams.length
      }
    }));
  };

  const [tournamentData, setTournamentData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(v1Basic.concat(`sports/${sportID}`));
        const data = response.data.data;
        setSports(data);

        const schedule = data.schedule || [];
        if (schedule.length === 0) {
          setNoSchedule(true);
          setTournamentData([]);
        } else {
          setNoSchedule(false);
          const sortedData = schedule.sort((a, b) => a.match_no - b.match_no);
          const updatedData = sortedData.map((item) => {
            const firstTeam = formatTeam(item.firstTeam, item.winningTeam);
            const secondTeam = formatTeam(item.secondTeam, item.winningTeam);
            return {
              id: item.id,
              name: `Match ${item.match_no}`,
              nextMatchId: item.next_match_id,
              tournamentRoundText: item.match_round,
              startTime: `${formatDate(item.date)} ( ${formatTime(item.time)} )`,
              state: '',
              participants: [firstTeam, secondTeam],
            };
          });
          setTournamentData(updatedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [sportID]);
  // console.log(tournamentData)

  const customTheme = createTheme({
    textColor: { 
      main: '#fff', 
      highlighted: '#000', 
      dark: '#fff' ,
      title: '#000'
    },
    matchBackground: { 
      wonColor: '#FFC125', 
      lostColor: '#7A0002' 
    },
    score: {
      background: { 
        wonColor: '#FFC125', 
        lostColor: '#7A0002' 
      },
      text: { 
        highlightedWonColor: '#7BF59D', 
        highlightedLostColor: '#FB7E94' 
      },
    },
    border: {
      color: '#7A0002',
      highlightedColor: '#FFC125',
    },
    roundHeader: { 
      backgroundColor: '#FFC125', 
      fontColor: '#FFF' 
    },
    connectorColor: '#7A0002',
    connectorColorHighlight: '#FFC125',
    svgBackground: '#d1d1d1',
  });

  const { width, height } = useWindowSize();
  const finalWidth = Math.max(width - 50, 500);
  const finalHeight = Math.max(height - 70, 500);
  const schedule = sports.schedule || [];

  return (
    <>
    {token==null && (<Navigate to={'/login'} replace={true}/>)}
    {role == 'College Representative' && (<Navigate to={'/'} replace={true}/>)}
      <div className='m-3 px-3 py-5 bg-white rounded-md -mr-3 font-bold text-2xl text-prc'>
        Schedule: {sports.title}
        <div className='bg-prc h-1 rounded-lg mb-5 mt-2 max-w-96'></div>

        <div className='font-normal text-sm'>
          Venue: {sports.venue}
        </div>
        <ul className="grid w-full gap-2 md:grid-cols-3 mt-2">
        {schedule.map((item, index) => (
            <ScheduleUpdate index={index} item={item} key={index}/>
        ))}
        </ul> 
      </div>
      <Modal open={modalIsOpen} onClose={handleModal}
        aria-labelledby="modal-title" aria-describedby="modal-description"
        className="flex justify-center items-center h-screen"
      >
        <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
          <div className='flex'>
            <div className='m-3 bg-white rounded-md '>
              <h3 id="modal-title" className='font-semibold text-xl'>Arrange the teams </h3>
              <p id="modal-description " className='max-w-80 '>This Popup is for arranging the team by number to generate the schedule </p>
              <div className='mb-4 mt-2 bg-prc h-0.5 w-full max-w-96'></div>
              {warningIsVisible ? (
                <div className='transition transform translate-x-4 -ml-4 mr-4 border-l-4 border-prc mb-2 text-prc font-normal bg-prc rounded-md bg-opacity-30 p-3 flex flex-row'>
                  <p className='font-bold mr-2'>WARNING: </p>A team is selected more than once
                </div>
              ) : (
                <div></div>
              )}
              {teams.map((item, index) => (
                <div className='flex' key={index}>
                  <div className='bg-prc text-white p-3 px-5 mb-1 rounded-md mr-3'>{index + 1}</div>
                  <div className='justify-center content-center'>
                    <ul className="w-full gap-1 flex">
                      {teams.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <input type="radio" id={`${subItem.title}-${index}`} name={`team-id-${index}`} value={subItem.id} className="hidden peer" onClick={handleChange} defaultChecked={subIndex === 0} required />
                          <label htmlFor={`${subItem.title}-${index}`}
                            className="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-prc dark:border-prc dark:border-2 dark:peer-checked:border-src dark:bg-white dark:hover:bg-src dark:hover:bg-opacity-50">
                            <div className="block">
                              <div className="w-full text-sm font-bold">{subItem.title}</div>
                            </div>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
              <button type='button' onClick={generateHandle} className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Generate</button>
            </div>
          </div>
        </Box>
      </Modal>
          {noSchedule ? (
            <button className='flex-1 justify-center py-2 w-full cursor-pointer flex bg-prc text-white m-3 text-center rounded-md hover:scale-101 hover:drop-shadow-sm' onClick={generateHandle}>
              Generate Match
            </button>
          ) : (
          <>
          <div></div>
          <div className='flex content-center w-full m-3 bg-[#d1d1d1] p-3 rounded-md'>
            <div className='flex-1'></div>
            <div className='flex-none bg-prc w-auto'>
            <>
              {loading ? ( // Display loading indicator while data is loading
                <div>Loading...</div>
              ) : (
                <SingleEliminationBracket
                  matches={tournamentData}
                  matchComponent={Match}
                  theme={customTheme}
                  options={{
                    style: {
                      roundHeader: {
                        backgroundColor: customTheme.roundHeader.backgroundColor,
                        fontColor: customTheme.roundHeader.fontColor,
                      },
                      connectorColor: customTheme.connectorColor,
                      connectorColorHighlight: customTheme.connectorColorHighlight,
                    },
                  }}
                  svgWrapper={({ children, ...props }) => (
                    <SvgViewer
                      background={customTheme.svgBackground}
                      SVGBackground={customTheme.svgBackground}
                      width={finalWidth}
                      height={finalHeight}
                      {...props}
                    >
                      {children}
                    </SvgViewer>
                  )}
                />
              )}
            </>
          </div>
          <div className='flex-1'></div>
        </div>
        </>
        )}
    </>
  );
}

function formatTeam(team , winningTeam) {
 
  if (!team) {
    return { id: '', name: '', isWinner: '' }
  } else{
    if(!winningTeam){
      console.log(winningTeam) 
      return { id: team.id, name: team.title }

    }else{
      return { id: team.id, name: team.title, isWinner: winningTeam.id == team.id }
    }
  }
}


export function ScheduleUpdate({item, index}) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [schedule, setSchedule] = useState({
      date : null,
      time : null,
      match_no : '',
      match_round : '',
      next_match_id : null,
      first_team_id : null,
      second_team_id : null,
      winner_team_id : null,
      sports_id : item.sports.id,
      lib_game_statuses_id : 1,
      referee_full_name: ''
  })
  const [canBeSchedule, setCanBeSchedule] = useState(false)
  const [first, setFirst] = useState('TBD');
  const [second, setSecond] = useState('TBD');
  const [isFinished, setIsFinished] = useState(false);

  // console.log(item)
  const handleModal = () => setModalIsOpen(!modalIsOpen)
  const handleSubmit =async (e) => {
    console.log('data:',schedule)
    e.preventDefault()
    try{
      const response = await axios.put(v1Basic.concat(`schedule/${item.id}`), schedule, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('Response:', response.data);
    } catch(error){
      console.log(error)
    };
    handleModal()
  }
  useEffect(()=> {
    const firstTeam = checkTeam(item.firstTeam)
    const secondTeam = checkTeam(item.secondTeam)

    if(item.winningTeam === null){
      setIsFinished(false)
    }else{setIsFinished(true)}

    setFirst(firstTeam.title)
    setSecond(secondTeam.title)

    setSchedule({
      ...schedule,
      date : item.date,
      time : item.time,
      match_no : item.match_no,
      match_round : item.match_round,
      next_match_id : item.next_match_id,
      first_team_id : firstTeam.id,
      second_team_id : secondTeam.id,
      winner_team_id : null,
      sports_id : item.sports.id,
      lib_game_statuses_id : 1,
      referee_full_name : item.referee_full_name,
    })
  }, [item])

  useEffect(()=>{
    if(first != null || second != null){
      setCanBeSchedule(true)
    }
    else{
      setCanBeSchedule(false)
    }
  },[first, second])

  const handleChange = (e) => {
    const {name, value} = e.target
    setSchedule({
      ...schedule,
      [name]: value
    })
    console.log(schedule)
  }
  if(canBeSchedule){
    return (
      <>
      <Modal open={modalIsOpen} onClose={handleModal}
        aria-labelledby="modal-title" aria-describedby="modal-description"
        className="flex justify-center items-center h-screen"
      >
        <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
          <h3 id="modal-title" className='font-semibold text-xl'>Set Schedule</h3>
          <p id="modal-description " className='mb-4'>This Popup is for setting the Schedule.</p>
          <div className=''>
            Match {schedule.match_no}
            <div className=' select-none flex rounded-xl hover:scale-105 my-2 mb-6 border-prc border-b-4 border-t-4 justify-center py-3'>
              {first == null || second == null ? (
                <>
                {first != null ? (
                  <>
                  <div className='mr-3 text-3xl font-bold  content-center'>{first}</div>
                  <div className='mr-3 text-xl content-center'>vs</div>
                  <div className=' text-3xl font-bold content-center'>TBD</div>
                  </>
                ) : (
                  <>
                  <div className='mr-3 text-3xl font-bold  content-center'>TBD</div>
                  <div className='mr-3 text-xl content-center'>vs</div>
                  <div className=' text-3xl font-bold content-center'>TBD</div>
                  </>
                )}
                </>
              ):(
                <>
                <div className='mr-3 text-3xl font-bold  content-center'>{first}</div>
                <div className='mr-3 text-xl content-center'>vs</div>
                <div className=' text-3xl font-bold content-center'>{second}</div>
                </>
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit} className='flex flex-col'>
            <div className=' flex-1 flex-row'>
                <div className=''>
                  <div className='flex'>
                    <div className='mr-3'>
                      <label className='text-sm'>Select Date</label>
                      <br></br>
                      <input type='date' placeholder='Ex. College of Computer Studies'
                              name='date' onChange={handleChange}
                              className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                      <br ></br>
                    </div>
                    <div>
                      <label className='text-sm'>Select Time</label>
                      <br></br>
                      <input  type='time' placeholder='Ex. CCS'
                      name='time' onChange={handleChange}
                      className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                  </div>

                  <div>
                    <label className='text-sm'>Referee Full Name</label>
                    <br></br>
                    <input  type='text' placeholder='Ex. Ramos Cruz'
                    name='referee_full_name' onChange={handleChange}
                    className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                  </div>
                </div>
            </div>
            <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Set & Send SMS</button>
          </form>
        </Box>
      </Modal>
      {isFinished ? (
        
      <li key={index}>
        <label className="border-x-4 shadow-sm border-prc inline-flex rounded-xl  flex-col items-center justify-between w-full p-5 text-gray-500 bg-prc bg-opacity-25 cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600  dark:text-prc dark:bg-prc  dark:bg-opacity-10 ">                           
        
          
          <div className="w-full text-sm font-bold truncate flex">
            <div className='flex-1 content-center justify-center'>Match {item.match_no} {item.match_round}</div>
            <div className='flex-1'></div>
            <div className=' flex-1 w-full text-center border-r-4 border-prc rounded-md bg-green-100 font-normal text-sm mt-2'>Winner: {item.winningTeam == null ? (<div></div>) : (item.winningTeam.title)}</div>
          </div>
          <div className='flex w-full'>
            {item.date || item.time ? 
            (
              <div className='flex-1 mr-2 text-xl'>
                {formatDate(item.date)}
                <div className='text-sm font-medium'> {formatTime(item.time)}</div>
              </div>
            ) : (
              <div className='flex-1 mr-2 text-xl'>
                Unscheduled
                <div className='text-sm'> N/A</div>
              </div>
            )} 
            <div className=' flex  content-center '>
  
              {item.firstTeam == null ? (<div className='content-center '> TBD </div>):( <div className='content-center '>{item.firstTeam.title}</div>)}
              <div className='content-center  font-thin text-2xl mx-3'>/</div>
              {item.secondTeam == null ? (<div className='content-center '> TBD </div>):( <div className='content-center '>{item.secondTeam.title}</div>)}
            </div>
  
          </div>
        </label>
      </li>
      ) : (
  
      <li key={index} onClick={handleModal}>
          <label className="border-x-4 shadow-sm border-prc inline-flex rounded-xl  flex-col items-center justify-between w-full p-5 text-gray-500 bg-white cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-prc dark:bg-white  hover:scale-101 dark:hover:bg-prc dark:hover:bg-opacity-10">                           
            
            <div className="w-full text-sm font-bold truncate">{item.match_round === 'Bronze' ? 'Bronze Match' : `Match ${item.match_no}`}</div>
            <div className='flex w-full'>
              {item.date || item.time ? 
              (
                <div className='flex-1 mr-2 text-xl'>
                  {formatDate(item.date)}
                  <div className='text-sm font-medium'> {formatTime(item.time)}</div>
                </div>
              ) : (
                <div className='flex-1 mr-2 text-xl'>
                  Unscheduled
                  <div className='text-sm'> N/A</div>
                </div>
              )} 
              <div className=' flex  content-center '>
  
                {item.firstTeam == null ? (<div className='content-center '> TBD </div>):( <div className='content-center '>{item.firstTeam.title}</div>)}
                <div className='content-center  font-thin text-2xl mx-3'>/</div>
                {item.secondTeam == null ? (<div className='content-center '> TBD </div>):( <div className='content-center '>{item.secondTeam.title}</div>)}
              </div>
  
            </div>
          </label>
      </li>
      )}
      </>
    )
  }else{
    return(<></>)
  }
}

export function checkTeam(team) {
  if(team != null){
    return team
  } else {
    return 'TBD'
  }
}

export function formatDate(data){
  if(data == null){
    return'Unscheduled' 
  }
  
  const date = new Date(data)

  // Extract year, month (formatted as short), and day
  const year = date.getFullYear();
  const month = date.toLocaleString('en-US', { month: 'short' }); // Short month name
  const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits

  // Return in "YYYY Month DD" format
  return `${year} ${month} ${day}`;
  // return formatter.format(date)
}

export function formatTime(timeString) {
  if(timeString != null){
    
  }
  const today = new Date().toISOString().split('T')[0];
  const dateTimeString = `${today}T${timeString}`;
  
  const time = new Date(dateTimeString);

  if (isNaN(time.getTime())) {
    return '';
  }

  return time.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
}

function shuffleTeams(data){
  return data
  .map(value => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);
}