import React, { useEffect, useState } from 'react'
import { CollegeRank } from '../../../Components/Cards'
import axios from 'axios';
import { baseUrl } from '../../../Resource/api';

export default function Ranking() {
  const [tally,setTally] = useState([])
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/v1/compute/tally`);
        
        const sortByPoints = (array) => {
          return array.sort((a, b) => b.point - a.point);
        };
        setTally(sortByPoints( response.data.data));
      } catch (error) {
      console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData(); 
  }, []);
  console.log(tally) 
  return (
    <div className=' bg-cont mt-3 rounded-r-md p-3'>
      <h1 className='text-2xl font-bold text-prc mb-3'>Intramurals Ranking</h1>
      {tally.map((item, index) => (
        <CollegeRank counter={index+1} key={index} data={item}/>
      ))}
    </div>
  )
}
