import React, { useContext, useEffect, useState } from 'react'
import MatchSummary from '../../../Components/Cards'
import { Navigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { v1Basic } from '../../../Resource/api'
import { separateByDate, sortByDate } from '../Index'
import { sortedByTime } from '../Home/Schedule'
import { formatDate } from '../../Admin/Schedule/Bracketing'
import Loading from '../../../Components/Loading'
import { AppContext } from '../../../Context/AppContext'

export default function SportSchedule( data = null ) {
  const sortedData = sortedByTime(data.data)

  return (
    <>


    <div className=' pt-5'>
      <div className=' pl-5 mb-2'>
        <h1 className=' text-2xl font-extrabold text-prc mb-3'>{formatDate(sortedData[0].date)}</h1>
        <div className=' bg-prc w-full h-0.5 -mt-1 '></div>
      </div>
      <ul className="grid w-full gap-2 md:grid-cols-1">

      {sortedData.map((item,index) =>(
        <MatchSummary key={index} item={item} index={index}/>
      ))}
      </ul>
    </div>
    </>
  )
}


export function SportsSchedulePage() {
  const { sportID } = useParams()
  // const { token } = useContext(AppContext)
  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState([]);
  const [noSchedule, setNoSchedule] = useState(true);
  // const [isEmpty, setIsEmpty] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(v1Basic.concat(`sports/${sportID}`));
        const data = sortByDate( response.data.data.schedule );

        setSports(separateByDate(data));

        const schedule = data || [];
        if (schedule.length === 0) {
          setNoSchedule(true);
        } else {
          setNoSchedule(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    
  }, [sportID]);
  console.log(sports)
  return (
    <>
    {/* {token==null && (<Navigate to={'/login'} replace={true}/>)} */}
    
    {loading ? (
        <Loading />
      ) : (
        <>
        {noSchedule ? (
          <div className='w-full h-screen text-prc content-center justify-center text-center font-extralight text-4xl'>No Scheduled Match</div>
        ) : (
          <div className=' mb-10'>
          {sports.map((item, index) => (

            <SportSchedule key={index} data={item}/> 
          ))}
          </div>
        )}
        </>
      )}
    </>
  )
}

