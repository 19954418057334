import React, { useContext, useEffect, useState } from "react";
import Banner from "./Home/Banner";
import Schedule from "./Home/Schedule";
import Axios from "axios";
import Incident from "./Home/Incident";
import Ranking from "./Result/Ranking";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { SportsInformation } from "../../Components/Cards";
import { baseUrl, v1Basic } from "../../Resource/api";
import axios from "axios";
import Loading from "../../Components/Loading";
import { AppContext } from "../../Context/AppContext";

function HomePage(){
    const [schedule,setSchedule] = useState([])
    const [banner,setBanner] = useState([])
    const [loading, setLoading] = useState(true);
    
    const {token} = useContext(AppContext)
    // console.log(token)
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${v1Basic}schedule`);
                const bannerResponse = await axios.get(v1Basic.concat('banner/1'))

                // Step 1: Sort by date
                const sortedResponse = sortByDate(response.data.data)

                // Step 2: Group by date with index-based structure
                const data = separateByDate(sortedResponse) 
                
                setSchedule(data);
                setBanner(bannerResponse.data.data)
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
            };
        fetchData();  
    }, []);

    // console.log(banner)
    return(
        <>
        {token==null && (<Navigate to={'/'} replace={true}/>)}

        {loading ? (
            <Loading />
            ):(
            <>
                <div className="flex pr-3">
                    <div className=" flex-1">
                        <Banner data={banner} />
                        <div className="hidden  flex flex-none min-[870px]:hidden  w-auto">
                            <Incident /> 
                        </div>
                        {schedule.map((item, index) => (

                            <Schedule key={index} data={item}/>
                        ))}
                    </div>
                    <div className="hidden flex-none max-[870px]:hidden">
                        <Incident />
                    </div>
                </div>
            </>
        )}
        </>
    );
}

export function ResultPage() {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const {token} = useContext(AppContext)
    

    useEffect(() => { 
        const fetchData = async () => {
          setLoading(true);
          try {
            const bannerResponse = await axios.get(v1Basic.concat('banner/1'))
            
            setData(bannerResponse.data.data)
          } catch (error) {
            //   console.error("Error fetching data:", error)
              setData({data:{image:null}})
          } finally {
              setLoading(false)
          }
        };
    
        fetchData();
      }, []);
    // console.log(data) 
    return (
        <>    
            {token==null && (<Navigate to={'/result'} replace={true}/>)}
            {loading ? (
            <Loading />
            ):(
            <>
            
                <div className="flex pr-3">
                    <div className=" flex-1">
                        <Banner data={data}/>
                        <div className="hidden  flex flex-none min-[870px]:hidden  w-auto">
                            <Incident />
                        </div>
                        <Ranking />
                    </div>
                    <div className="hidden flex-none max-[870px]:hidden">

                        <Incident />
                    </div>
                </div>
            </>
            )}
        </>
    )
}

export function SchedulePage({loc}) {
    const location = loc;
    const [sports, setSports] = useState([])
    useEffect(() => {
        axios.get(v1Basic.concat('sports')).then((res) => {
            setSports(res.data.data)

        })

    }, [])
    const {token} = useContext(AppContext)
    return (
        <>
            {token==null && (<Navigate to={'/login'} replace={true}/>)}
            <div className="flex h-full">
                <div className="bg-white h-screen overflow-x-hidden overflow-scroll flex-none pb-32 sticky top-0">
                    <div className="flex flex-col">
                        <div className="text-prc text-2xl font-bold m-3 mb-0 py-2">
                            SPORTS
                        </div> 
                        <div className="-mt-1 bg-prc h-0.5 rounded-md mx-3 mb-3"></div>
                        {sports.map((item, index) => (
                            <SportsInformation data={item} sportID={item.id} currentURL={location} key={index}/>
                        ))}
                    </div>
                </div>
                <div className="flex-1 pr-4">
                    <Outlet/>
                </div>
            </div>

        </>
    )
}

export function separateByDate(data){
    const groupedByIndex = [];
    let currentDate = null;

    
    data.forEach((match) => {
        if (currentDate !== match.date) {
        // If the date changes, create a new sub-array for the new date
            currentDate = match.date;
            groupedByIndex.push([]);
        }
        // Add the match to the current sub-array
        groupedByIndex[groupedByIndex.length - 1].push(match);
    });

    return groupedByIndex
}

export function sortByDate(data){
    const sortedResponse = data.sort((a, b) => new Date(a.date) - new Date(b.date));
    return sortedResponse
}

export default HomePage

