import axios from 'axios';
import React, { useState,useEffect, useContext } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { v1Basic } from '../../../Resource/api';
import { Box, Modal } from '@mui/material';
import { AppContext } from '../../../Context/AppContext';
import Loading from '../../../Components/Loading'
export default function Team() {
    const file = 'player'
    const {teamID} = useParams();
    const navigate = useNavigate()
    const {token} = useContext(AppContext)
    const apiPlayer = v1Basic.concat("player");
    const [loading, setLoading ] = useState(false)
    const [team, setTeam] = useState ({
        id: null,
        title: '',
        logo: null,
        sports_id: {
          id: null,
          title: '',
          logo: null,
          venue: ''
        },
        college_id: {
          id: null,
          acronym: '',
          title: '',
          logo: null
        },
        coach_id: {
          first_name: '',
          last_name: '',
          middle_name: '',
          phone_no: '',
          lib_gender_id: {
            id: null,
            desc: ''
          }
        },
        lib_award_id: {
          id: null,
          desc: ''
        },
        players: []
    })
    const [sports, setSports] = useState([])

    const [playerForm, setPlayerFrom] =useState({
        player: {
            student_no: '',
            team_id: teamID,
            position_id: '',
            basic_information_id: "0",
            lib_player_status_id: '1',
            cor: null, // Certificate of Registration (PDF)
            med_cert: null, // Medical Certificate (PDF)
            psa: null, // PSA (PDF)
        },
        info:{
            first_name: '',
            middle_name: '',
            last_name: '',
            phone_no: '',
            lib_gender_id: '',

        }
    })

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const handleModal = () => setModalIsOpen(!modalIsOpen)
    const handleChange = (e) => {
        const {name, value} = e.target
        if(name === "position_id" ||name === "student_no"){
            setPlayerFrom((prevForm) =>({
                ...prevForm,
                player:{
                    ...prevForm.player,
                    [name]: value
                }
            }))
        }else {
            setPlayerFrom((prevForm) =>({
                ...prevForm,
                info:{
                    ...prevForm.info,
                    [name]: value
                }
            }))
        }
        
        // console.log(`${name}: ${value}`)
        // console.log(playerForm)
    }

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const selectedFile = files[0];

            // Ensure the file is a PDF
            if (selectedFile.type !== 'application/pdf') {
                alert('Only PDF files are allowed!');
                e.target.value = ''; // Reset input
                return;
            }

            setPlayerFrom((prevForm) => ({
                ...prevForm,
                player: {
                    ...prevForm.player,
                    [name]: selectedFile // Store the selected file in the state
                }
            }));
        }
    };
    const handleDelete = async (e) => {
        e.preventDefault()
        e.target.disabled = true
        setLoading(true)
        try{
            const response = await axios.delete(v1Basic.concat(`team/${teamID}`))
            navigate('/admin/master-file')
        }catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error Response Data:', error.response.data);
                console.error('Error Response Status:', error.response.status);
                console.error('Error Response Headers:', error.response.headers);
                console.log(playerForm)
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error Request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error Message:', error.message);
            }
            
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(loading) return

        setLoading(true)
        const formData = new FormData();

        // Append form data (non-file data)
        for (const key in playerForm.player) {
            if (key !== 'cor' && key !== 'med_cert' && key !== 'psa' && key !== 'logo') {
                formData.append(`player[${key}]`, playerForm.player[key]);
            }
        }
        for (const key in playerForm.info) {
            formData.append(`info[${key}]`, playerForm.info[key]);
        }

        // Append files (PDFs)
        for (const key of ['cor', 'med_cert', 'psa', 'logo']) {
            if (playerForm.player[key]) {
                formData.append(`player[${key}]`, playerForm.player[key]);  // Changed to use player[key]
            }
        }

        try{
            for (let pair of formData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }
            const response = await axios.post(apiPlayer, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
            handleModal()
            navigate(0)
        }catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    const api = v1Basic.concat(`team/${teamID}`);
    useEffect (() => {
        setLoading(true)
        axios.get(api).then((res) => (
            setTeam(res.data.data)
        ))
        setLoading(false)
         
    }, [])
    const position = sports?.position || []
    useEffect (() => {  
        setLoading(true)

        team.sports_id.id ? (
            axios.get(v1Basic.concat(`sports/${team.sports_id.id}`)).then((res) => (
                setSports(res.data.data)
            ))
        ) : (
            console.log("")  
        )
        setLoading(false)

    }, [team])

    const [searchData, setSearchData]= useState({
        type: file,
        term: '',
        team_id: teamID,
        college_id: null,
      })
    
      const handleSearch = async () => {
        try{
          setLoading(true)
          const response = await axios.put(v1Basic.concat('search'),searchData, {
            headers: {
              'Content-type': 'application/json'
            }
          })
          setTeam(response.data)
          // console.log(response)
        } catch (error) {
          console.error(error)
        }finally {
    
          setLoading(false)
        }
      }
    
      const handleTerm = (e) => {
        const {name, value} =e.target
        setSearchData({
          ...searchData,
          [name]: value
        })
      } 
    return (
        <>
        {token==null && (<Navigate to={'/login'} replace={true}/>)}
        {loading && <Loading />}
        <Modal open={modalIsOpen} onClose={handleModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen z-40"
        >
            <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
            {loading && <Loading />}

            <h3 id="modal-title" className='font-semibold text-xl'>New Player</h3>
            <p id="modal-description " className='mb-4'>This Popup is for adding a new Player.</p>
            <form onSubmit={handleSubmit}>

                <div className='  flex'>
                    <div className=' flex-1 mr-2'>
                        <label className='text-sm'>First Name</label>
                        <input type='text' placeholder='Ex. John' required
                                name='first_name' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                    <div className=' flex-1 mr-2'>
                        <label className='text-sm'>Last Name</label>
                        <input type='text' placeholder='Ex. Ramos' required
                                name='last_name' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                    <div className=' flex-1'>
                        <label className='text-sm'>Middle Name</label>
                        <input type='text' placeholder='Ex. Cruz' required
                                name='middle_name' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                </div>
                <div className='  flex'>
                    <div className='flex-none mr-2'>
                        <label className='text-sm'>Gender</label>
                        <ul className="grid w-auto gap-1 md:grid-cols-2">
                            <li>
                                <input type="radio" id={`gender-male`} name="lib_gender_id" value={1}  className="hidden peer" onClick={handleChange} required />
                                <label htmlFor={`gender-male`} 
                                className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                    <div className="block">
                                        <div className="w-full text-sm font-bold truncate">Male</div>
                                    </div>
                                </label>
                            </li>
                            <li>
                                <input type="radio" id={`gender-female`} name="lib_gender_id" value={2}  className="hidden peer" onClick={handleChange} required />
                                <label htmlFor={`gender-female`} 
                                className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                    <div className="block">
                                        <div className="w-full text-sm font-bold truncate">Female</div>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className=' flex-1 mr-2'>
                        <label className='text-sm'>Phone No</label>
                        <input type='number' placeholder='Ex. 09748262912' required
                                name='phone_no' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                    <div className=' flex-1 mr-2'>
                        <label className='text-sm'>Student No</label>
                        <input type='number' placeholder='Ex. 2022400572' required
                                name='student_no' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                </div>
                
                {/* Requirements */}
                <label className='text-sm'>Certificate of Registration {`(COR)`}</label><br></br>
                <input required onChange={handleFileChange} type="file" name='cor' accept="application/pdf" className='mb-2  w-full rounded-md p-2 bg-def-t bg-opacity-5 cursor-pointer'/>
                
                <label className='text-sm'>Medical Certificate</label><br></br>
                <input required onChange={handleFileChange} type="file" name='med_cert' accept="application/pdf" className='mb-2  w-full rounded-md p-2 bg-def-t bg-opacity-5 cursor-pointer'/>
                
                <label className='text-sm'>PSA</label><br></br>
                <input required onChange={handleFileChange} type="file" name='psa' accept="application/pdf" className='mb-2  w-full rounded-md p-2 bg-def-t bg-opacity-5 cursor-pointer'/>
                {/* pos */}
                <div> 
                    <label className='text-sm'>Select Position</label>
                    <ul className="grid w-full gap-1 md:grid-cols-5">
                    {position.map((item, index) => (
                        <li key={index}>
                            <input type="radio" id={`${item.acronym}-${item.id}`} name="position_id" value={item.id}  className="hidden peer" onClick={handleChange} required />
                            <label htmlFor={`${item.acronym}-${item.id}`} 
                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                <div className="block">
                                    <div className="w-full text-sm font-bold truncate">{item.desc}</div>
                                    
                                </div>
                            </label>
                        </li>
                    ))}
                    </ul>
                </div>
                <button type='submit' className='mt-5 bg-prc rounded-md py-2 w-full text-white'>{loading ? 'Sending': 'Add Player'}</button>
            </form>
            </Box>
        </Modal>
        <div className=' m-2 mt-5 flex'>
            <div className=' flex-none p-3 mr-2 bg-cont rounded-lg h-full pb-5'>
                <div>
                <div className=' flex flex-row '>
                        <img src={team.logo != null ? (team.logo):(require('../../../Resource/logo-one.png'))} className=' rounded-full h-24 w-24' alt='img'/>
                    </div>
                </div>
                <div className=' flex-1 content-center mt-5 min-w-64'>
                    <div className='flex flex-col  '>
                        <div className=' flex-1 -mt-1 text-sm'>
                            Team Name
                        </div>
                        <div className=' flex-1 font-bold text-2xl -mt-2'>
                            {team.title}
                        </div>
                    </div>

                    <div className='flex flex-col mt-2 '>
                        <div className=' flex-1 -mt-1 text-sm'>
                            Coach
                        </div>
                        <div className=' flex-1 font-bold text-2xl -mt-2'>
                            {team.coach_id.last_name}, {team.coach_id.first_name} {team.coach_id.middle_name.charAt(0).toUpperCase() + '.'}
                        </div>
                    </div>

                    <div className='flex flex-col mt-2 '>
                        <div className=' flex-1 -mt-1 text-sm'>
                            College
                        </div>
                        <div className=' flex-1 font-bold text-2xl -mt-2'>

                            {team.college_id.title}
                        </div>
                    </div>

                    <div className='flex flex-col mt-2 '>
                        <div className=' flex-1 -mt-1 text-sm'>
                            Sport
                        </div>
                        <div className=' flex-1 font-bold text-2xl -mt-2'>
                            {team.sports_id.title}

                        </div>
                    </div>
                </div>
            <div className='bg-prc rounded-md text-center py-2 font-bold text-white mt-4 hover:scale-101 cursor-pointer' onClick={handleDelete}>Remove Team</div>
            </div>
            <div className='flex-1'>
                <div className='bg-cont flex-col flex   rounded-lg'>
                    <div className='flex-1 m-3 mb-2'>
                        <div className='flex'>
                        <div className=' flex-1 '>
                            <div className=' flex'>
                            {/* <input name='term' type='text' onKeyDown={(e) => e.key === 'Enter' && handleSearch(e)} onChange={handleTerm} placeholder="Find Player..."
                                    className='bg-transparent p-2 border-2 rounded-l-md flex-1' />
                            <div className='p-3 flex-none bg-prc rounded-r-md' onClick={handleSearch}>
                                <span className='icon-[majesticons--search] bg-white w-5 h-5 mt-2 mx-2'></span>
                            </div> */}
                                <div className='flex w-full justify-center p-2  text-center content-center flex-none bg-prc text-white rounded-md cursor-pointer' onClick={handleModal}>
                                    Add Player
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {!team.players || team.players.length == 0 ? (
                        <div className='w-full h-screen text-prc content-center justify-center text-center font-extralight text-4xl'>No Player</div>
                    ):(
                        <>
                        <table>
                        <thead className='bg-prc text-white text-left'>
                            <tr>
                                <th className='px-6 py-3'>Student No.</th>
                                <th className='px-6 py-3'>Full Name</th>
                                <th className='px-6 py-3'>Gender</th>
                                <th className='px-6 py-3'>Position</th>
                                <th className='px-6 py-3'>Status</th>
                                <th className='px-6 py-3'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {team.players.map((item, index)=>(
                                <PlayerInformation key={index} data={item} team={team} position={position}/>
                            ))}
                            </tbody>
                    </table>
                    </>
                    )}
                </div>
            </div>
        </div>
        </>
    )
}

export function PlayerInformation({data, team, position}) {
    const apiPlayer = v1Basic.concat("player");
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const handleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen)
    const corUrl = data.cor.replace('https://api.sportstournamentmanagement.com/storage', 'https://sportstournamentmanagement.com/public/storage') || ''
    const medCert = data.med_cert.replace('https://api.sportstournamentmanagement.com/storage', 'https://sportstournamentmanagement.com/public/storage') || ''
    const psa = data.psa.replace('https://api.sportstournamentmanagement.com/storage', 'https://sportstournamentmanagement.com/public/storage') || ''
    const [approveModalIsOpen, setApporveModalIsOpen] = useState(false)
    const handleApporveModal = () => setApporveModalIsOpen(!approveModalIsOpen)
    const {role} = useContext(AppContext)
    const [playerForm, setPlayerFrom] =useState({
        player: {
            student_no: data.student_no,
            team_id: team.id,
            position_id: data.position_id.id,
            basic_information_id: data.basic_information_id.id,
            lib_player_status_id: data.status.id
        },
        info:{
            first_name: data.basic_information_id.first_name,
            middle_name: data.basic_information_id.middle_name,
            last_name: data.basic_information_id.last_name,
            phone_no: data.basic_information_id.phone_no,
            lib_gender_id: data.basic_information_id.gender.id,

        }
    })
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const handleModal = () => setModalIsOpen(!modalIsOpen)
    const handleChange = (e) => {
        const {name, value} = e.target
        if(name === "position_id" ||name === "student_no"){
            setPlayerFrom((prevForm) =>({
                ...prevForm,
                player:{
                    ...prevForm.player,
                    [name]: value
                }
            }))
        }else {
            setPlayerFrom((prevForm) =>({
                ...prevForm,
                info:{
                    ...prevForm.info,
                    [name]: value
                }
            }))
        }
        
        console.log(`${name}: ${value}`)
        console.log(playerForm)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.target.disabled = true;
        try{
            const updatePlayer = v1Basic.concat('player')
            const response = await axios.put(updatePlayer.concat(`/${data.id}`), playerForm, {
                headers: {
                  'Content-Type': 'application/json'
                }
              })
        }catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error Response Data:', error.response.data);
                console.error('Error Response Status:', error.response.status);
                console.error('Error Response Headers:', error.response.headers);
                console.log(playerForm)
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error Request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error Message:', error.message);
            }
            
        } finally {
            e.target.disabled = false;

        }
        handleModal()
    }
    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
    
        try{
          const response = await axios.delete(apiPlayer.concat(`/${data.id}`))
          console.log('Response:', response.data);
        } catch(error){
          console.log(error)
        };
        handleDeleteModal()
        window.location.reload()
    }

    const handleApporve = async (e) => {
        e.preventDefault();
    
        // Update `playerForm` with the new status
        const updatedPlayerForm = {
            ...playerForm,
            player: {
                ...playerForm.player,
                lib_player_status_id: 2,
            },
        };

        setPlayerFrom(updatedPlayerForm); // Update state with the new form
        try{
            const response = await axios.put(apiPlayer.concat(`/${data.id}`), updatedPlayerForm, {
                headers: {
                'Content-Type': 'application/json'
                }
            })
            console.log('Response:', response);
        } catch(error){
            console.log(error)
        };
        handleApporveModal()
    }
  return (
    <>
    <Modal open={deleteModalIsOpen} onClose={handleDeleteModal}
        aria-labelledby="modal-title" aria-describedby="modal-description"
        className="flex justify-center items-center h-screen"
    >
        <Box className="bg-white rounded-lg shadow-lg text-def-t ">
            <h3 id="modal-title" className='font-semibold text-xl text-center bg-prc rounded-t-lg text-white py-3'>Remove Player</h3>
            <div className='p-6'>
                <p id="modal-description " className='mb-4'>Are you sure you want to delete <p className='font-bold'>{data.basic_information_id.last_name}, {data.basic_information_id.first_name} {data.basic_information_id.middle_name.charAt(0).toUpperCase() + '.'}</p></p>
                <form onSubmit={handleDeleteSubmit}>
                    <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Remove</button>
                </form>
            </div>
        </Box>
    </Modal>
    <Modal open={approveModalIsOpen} onClose={handleApporveModal}
        aria-labelledby="modal-title" aria-describedby="modal-description"
        className="flex justify-center items-center h-screen"
    >
        <Box className="bg-white rounded-lg shadow-lg text-def-t w-[90%] h-[80%] overflow-y-auto">
            <h3 id="modal-title" className='font-semibold text-xl text-center bg-src rounded-t-lg text-white py-3'>Approve Player</h3>
            <div className='p-6'>
                <p id="modal-description " className='mb-4'>Are you sure you want to Apprrove <p className='font-bold'>{data.basic_information_id.last_name}, {data.basic_information_id.first_name} {data.basic_information_id.middle_name.charAt(0).toUpperCase() + '.'}</p></p>
                <form onSubmit={handleApporve}>
                    <label className='text-xl font-bold'>COR</label>
                    <embed
                        src={corUrl} 
                        type="application/pdf" 
                        width="100%" 
                        height="600px" 
                    />
                    <label className='text-xl font-bold'>PSA</label>
                    <embed
                        src={psa} 
                        type="application/pdf" 
                        width="100%" 
                        height="600px" 
                    />
                    <label className='text-xl font-bold'>Medical Certificate</label>
                    <embed
                        src={medCert} 
                        type="application/pdf" 
                        width="100%" 
                        height="600px" 
                    />
                    <button type='submit' className='mt-2 bg-src rounded-md py-2 w-full text-white'>Approve</button>
                </form>
            </div>
        </Box>
    </Modal>
    <Modal open={modalIsOpen} onClose={handleModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
    >
        <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
        <h3 id="modal-title" className='font-semibold text-xl'>Update Player</h3>
        <p id="modal-description " className='mb-4'>This Popup is for updating Player.</p>
        <form onSubmit={handleSubmit}>

            <div className='  flex'>
                <div className=' flex-1 mr-2'>
                    <label className='text-sm'>First Name</label>
                    <input type='text' placeholder='Ex. John'
                            name='first_name' onChange={handleChange} value={playerForm.info.first_name}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                </div>
                <div className=' flex-1 mr-2'>
                    <label className='text-sm'>Last Name</label>
                    <input type='text' placeholder='Ex. Ramos'
                            name='last_name' onChange={handleChange} value={playerForm.info.last_name}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                </div>
                <div className=' flex-1'>
                    <label className='text-sm'>Middle Name</label>
                    <input type='text' placeholder='Ex. Cruz'
                            name='middle_name' onChange={handleChange} value={playerForm.info.middle_name}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                </div>
            </div>
            <div className='  flex'>
                <div className='flex-none mr-2'>
                    <label className='text-sm'>Gender</label>
                    <ul className="grid w-auto gap-1 md:grid-cols-2">
                        <li>
                            <input type="radio" id={`gender-male`} name="lib_gender_id" value={1}  className="hidden peer" onClick={handleChange} checked={1 == playerForm.info.lib_gender_id} required />
                            <label htmlFor={`gender-male`} 
                            className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                <div className="block">
                                    <div className="w-full text-sm font-bold truncate">Male</div>
                                </div>
                            </label>
                        </li>
                        <li>
                            <input type="radio" id={`gender-female`} name="lib_gender_id" value={2}  className="hidden peer" onClick={handleChange} checked={2 == playerForm.info.lib_gender_id}  required />
                            <label htmlFor={`gender-female`} 
                            className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                <div className="block">
                                    <div className="w-full text-sm font-bold truncate">Female</div>
                                </div>
                            </label>
                        </li>
                    </ul>
                </div>
                <div className=' flex-1 mr-2'>
                    <label className='text-sm'>Phone No</label>
                    <input type='number' placeholder='Ex. 09748262912'
                            name='phone_no' onChange={handleChange} value={playerForm.info.phone_no}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                </div>
                <div className=' flex-1 mr-2'>
                    <label className='text-sm'>Student No</label>
                    <input type='number' placeholder='Ex. 2022400572'
                            name='student_no' onChange={handleChange} value={playerForm.player.student_no}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                </div>
            </div>
            {/* pos */}
            <div> 
                <label className='text-sm'>Select Position</label>
                <ul className="grid w-full gap-1 md:grid-cols-5">
                {position.map((item, index) => (
                    <li key={index}>
                        <input type="radio" id={`${item.acronym}-${item.id}`} name="position_id" value={item.id}  className="hidden peer" onClick={handleChange} required checked={item.id == playerForm.player.position_id}  />
                        <label htmlFor={`${item.acronym}-${item.id}`} 
                        className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                            <div className="block">
                                <div className="w-full text-sm font-bold truncate">{item.desc}</div>
                                
                            </div>
                        </label>
                    </li>
                ))}
                </ul>
            </div>
            <button type='submit' className='mt-5 bg-prc rounded-md py-2 w-full text-white'>Update Player</button>
        </form>
        </Box>
    </Modal>
    <tr className=' text-def-t font-semibold'>
        <td className='px-6 py-3'>{data.student_no}</td>
        <td className='px-6 py-3'>{data.basic_information_id.last_name}, {data.basic_information_id.first_name} {data.basic_information_id.middle_name.charAt(0).toUpperCase() + '.'}</td>
        <td className='px-6 py-3'>{data.basic_information_id.gender.desc}</td>
        <td className='px-6 py-3'>{data.position_id.desc}</td>
        <td className='px-6 py-3'>{data.status.desc}</td>
        <td className='px-6 py-3 flex flex-row justify-center'>
            <div className='bg-prc flex-none content-center px-1 rounded-md mr-2 cursor-pointer' onClick={handleDeleteModal}>
                <span className='icon-[ic--round-delete] bg-white w-6 h-6 mt-2 mx-2'></span>
            </div>
            {data.status.id === 2 ?(
                <div className='bg-green-400 flex-none content-center rounded-md px-1 cursor-pointer' onClick={handleModal}>
                    <span className='icon-[bx--edit] bg-white w-6 h-6 mt-2 mx-2'></span>
                </div> 
            ):(
                <>
                {role != 'College Representative' && 
                <div className='bg-green-400 flex-none content-center rounded-md px-1 cursor-pointer' onClick={handleApporveModal}>
                    <span className='icon-[iconamoon--check-fill] bg-white w-6 h-6 mt-2 mx-2'></span>
                </div>
                }
                </>
            )}
        </td>
    </tr>
    </>
  )
}

export function BasicTeams( {data}) {
    // console.log(data)
    return (
      <tr className=' text-def-t font-semibold'>
          <td className='px-6 py-3'>{data?.title}</td>
          <td className='px-6 py-3'>{data?.sports_id?.title}</td>
          <td className='px-6 py-3'>{data?.coach_id?.last_name}, {data?.coach_id?.first_name} {data?.coach_id?.middle_name.charAt(0).toUpperCase() + '.'}</td>
          <td className='px-6 py-3'>{data?.players?.length}</td>
      </tr>
    )
  }

