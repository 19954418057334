import React, { useContext, useState } from 'react'
import { AppContext } from '../Context/AppContext'
import { baseUrl } from '../Resource/api';
import axios from 'axios';
import { Navigate, NavLink } from 'react-router-dom';
import Loading from '../Components/Loading'

export default function Login() {
    const {token, setToken} = useContext(AppContext)
    const [warningIsVisible, setWarningIsVissible]= useState(false)
    const [loading, setLoading] = useState(false);

    const [login, setLogin] = useState({
        email: '',
        password: ''
    })

    const handleChane = (e) => {
        const {name, value} =e.target
        setLogin({
            ...login,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const signIn = async () => {
            setLoading(true)
            try {

                // Fetch data from all endpoints
                const loginResponse = await axios.post(baseUrl.concat('/auth/login'), login, {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  })
                // console.log(loginResponse.data.token)
                localStorage.setItem('token', loginResponse.data.token)
                setToken(loginResponse.data.token)
            } catch (error) {
                // console.error("Error fetching data:", error);
                setWarningIsVissible(true)
            } finally {
                setLoading(false); 
                // navigate('/')
            }
        }
        signIn()
    }
    // console.log(token)
    return (
    <>
        {token && (
          <Navigate to="/admin" replace={true} />
          
        )}
        {loading && (<Loading />)}
        <div className="h-screen w-full bg-cover bg-center blur-none content-center justify-center" style={{ backgroundImage: `url(${require('../Resource/bg.png')})` }}>
            <div className='flex '>
                <div className='flex-1' ></div>
                <div className=' flex-none'>
                    <NavLink to={'/'} className='cursor-pointer select-none hover:scale-105 text-white mb-5'>{'< public'}</NavLink>
                    <div className='  flex flex-col mt-4'>
                        <div className='mb-7 flex-1  text-3xl font-thin text-white'>
                            <div className=' font-extrabold'>Welcome!</div>
                            <div className='text-sm max-w-52'>Please enter your credentials.</div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            {warningIsVisible ? (
                                <div className='transition transform translate-x-4  -ml-4 mr-4 border-l-4 border-src mb-2 text-src font-normal bg-src rounded-md bg-opacity-30 p-3 flex flex-row'>
                                <p className='font-bold mr-2'>INVALID: </p>Credential does not exist or match.
                                </div>
                            ):(<div></div>)} 
                            <div className='flex flex-row px-2 py-1 bg-gray-500 bg-opacity-45 flex-1 w-full rounded-md mb-2'>
                                <div className='h-full flex-none  content-center'>
                                    <div className='icon-[mdi--account] h-8 w-7 mt-1 bg-white'></div>
                                </div>
                                <div className=' flex-1 content-center ml-2'>
                                    <input name='email'  onChange={handleChane} className=' text-white font-semibold w-full focus:outline-none focus:ring-0 focus:border-0 h-full p-1 bg-transparent' type='text' placeholder='username'></input>
                                </div>
                            </div>
                            <div className='flex flex-row px-2 py-1 bg-gray-500 bg-opacity-45 flex-1 w-full rounded-md '>
                                <div className='h-full flex-none  content-center'>
                                    <div className='icon-[mdi--lock] h-8 w-7 -mb-1 mt-1 bg-white'></div>
                                </div>
                                <div className=' flex-1 content-center ml-2'>
                                    <input name='password' onChange={handleChane}  className=' text-white font-semibold w-full focus:outline-none focus:ring-0 focus:border-0 h-full p-1 bg-transparent' type='password' placeholder='password'></input>
                                </div>
                            </div>
                            <div className=' flex-1 content-center mt-2 opacity-90 select-none text-white text-sm italic '>
                                <div className=' flex'>
                                    {/* <input type='checkbox' className=' mr-2'/> Remember Me */}
                                </div>
                            </div>
                            <div>
                                <button type='submit' className='w-full  select-none mt-6 cursor-pointer hover:scale-105 drop-shadow-md flex-1 bg-src text-center text-white rounded-md py-2 font-bold'>
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='flex-1' ></div>
            </div>
        </div>
    </>
  )
}
