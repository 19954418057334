import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../Context/AppContext'
import axios from 'axios';
import { baseUrl, v1Basic } from '../Resource/api';
import { info } from '@iconify-json/bi/index.js';
import Loading from './Loading';
import { Box, Modal } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';

export default function MyProfile({ isMyAccount = false }) {
    const {accountID} = useParams()
    const {user,role , token} = useContext(AppContext)
    const [data, setData] = useState([])
    const [loading, setLoading]= useState(true)
    const [warningIsVisible, setWarningIsVissible]= useState(false)
    const [toNavigate ,setToNavigate] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const handleModal = () => setModalIsOpen(!modalIsOpen)

    const [formData, setFormData] = useState({
        user: {
            basic_information_id:  '',
            lib_role_id: '',
            email: '',
        },
        info:{
            first_name:'',
            middle_name: '',
            last_name: '',
            phone_no: '',
            lib_gender_id: ''
        }
    })

    useEffect(() => {
        if (isMyAccount) { 
            if (user) { // Ensure user exists
                setData(user.data);
            }
        } else {
            const fetchData = async () => {
                try {
                    setLoading(true)
                    const response = await axios.get(v1Basic.concat(`user/${accountID}`));
                    setData(response.data.data); // Set the fetched data
                    console.log('Fetched data:', response.data.data); // Log fetched data
                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    setLoading(false); 
                }
            };
            fetchData();
        }
    }, [accountID, user, isMyAccount]);

    useEffect(() => {
        if (data !=null || data == []) { // Only run if `data` is not null
            setFormData({
                user: {
                    basic_information_id:  data.basic_information?.id,
                    lib_role_id: data.role?.id,
                    email: data?.email,
                },
                info:{
                    first_name: data.basic_information?.first_name,
                    middle_name: data.basic_information?.middle_name,
                    last_name: data.basic_information?.last_name,
                    phone_no: data.basic_information?.phone_no,
                    lib_gender_id: data.basic_information?.gender.id
                }
            });
            setLoading(false); 
            // console.log('Form data set:', formData); // Log form data after setting it
        }
    }, [data]); // Run whenever `data` changes

    const handleSubmit = async (e) => {
        e.preventDefault()
        
        if(formData.user.password != null ){
            if(formData.user.password != formData.user.confirmPassword || formData.user.password.length < 8){
                setWarningIsVissible(true)
            }else{
                try{
                
                    const updateData = async () => {
                        setLoading(true)
                        try {
                            const response = await axios.put(v1Basic.concat(`user/${data.id}`), formData, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`
                                }
                            })
                            // console.log(response)
                        } catch (error) {
                            console.error("Error fetching data:", error);
                        } finally {
                            setLoading(false); 
                            setToNavigate(true)
                        }
                    }
                    updateData()
                    setWarningIsVissible(false)

                    handleModal()
                } catch (error) {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.error('Error Response Data:', error.response.data);
                        console.error('Error Response Status:', error.response.status);
                        console.error('Error Response Headers:', error.response.headers);
                        console.log(formData)
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.error('Error Request:', error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error('Error Message:', error.message);
                    }
                    
                }
            }
        } else {
            try{
                
                const updateData = async () => {
                    setLoading(true)
                    try {
                        const response = await axios.put(v1Basic.concat(`user/${data.id}`), formData, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        })
                        // console.log(response)
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    } finally {
                        setLoading(false); 
                        setToNavigate(true)
                    }
                }
                updateData()
                setWarningIsVissible(false)

                handleModal()
            } catch (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Error Response Data:', error.response.data);
                    console.error('Error Response Status:', error.response.status);
                    console.error('Error Response Headers:', error.response.headers);
                    console.log(formData)
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('Error Request:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error Message:', error.message);
                }
                
            }

    
        }
    }
    const handleChange = (e) => {
        const {name, value} = e.target
        if(name === "password" ||name === "email" ||name === "confirmPassword" ||name === "lib_role_id"){
            setFormData((prevForm) =>({
                ...prevForm,
                user:{
                    ...prevForm.user,
                    [name]: value
                }
            }))
        }else {
            setFormData((prevForm) =>({
                ...prevForm,
                info:{
                    ...prevForm.info,
                    [name]: value
                }
            }))
        }
        // console.log(formData)
    }
    // console.log(data)
    return (
        <div>
        {token==null && (<Navigate to={'/login'} replace={true}/>)}
        {/* {role != 'Admin' && (<Navigate to={'/'} replace={true}/>)} */}

            {loading ? (
                <Loading />
            ) : (
                <div className=' bg-white m-4 p-5 text-def-t rounded-lg border-x-4 border-prc'>
                    {toNavigate && (
                        <Navigate to={'/'} replace={true}/>
                    )}

                    <div className='text-2xl font-bold mb-3'>
                        {isMyAccount ? (
                            <div> My Profile</div>
                        ):(
                            <div> Profile</div>
                        )}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <label className='text-md font-semibold'>Account Information</label>
                        <div className=' flex-1'>
                            <label className='text-sm'>E-mail</label>
                            <input type='text' placeholder='Ex. john_ramos12@gmail.com'
                                    name='email' onChange={handleChange} value={formData.user.email}
                                    className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                        </div>
                        {warningIsVisible ? (
                            <div className='transition transform translate-x-4 -ml-4 mr-4 border-l-4 border-prc mb-2 text-prc font-normal bg-prc rounded-md bg-opacity-30 p-3 flex flex-row'>
                            <p className='font-bold mr-2'>WARNING: </p>Password is to weak or does not match.
                            </div>
                        ):(<div></div>)}
                        <div className=' flex-1'>
                            <label className='text-sm'>New Password</label>
                            <input type='password' placeholder='Enter Password'
                                    name='password' onChange={handleChange} value={formData.user.password}
                                    className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                        </div>
                        <div className=' flex-1'>
                            <label className='text-sm'>Re-enter New Password</label>
                            <input type='password' placeholder='Confirm Password'
                                    name='confirmPassword' onChange={handleChange} value={formData.user.confirmPassword}
                                    className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                        </div>
                        
                        <div className={`${role != 'Admin' && 'hidden'} mb-3`}>
                            <label className='text-sm'>Role</label>
                            <ul className="grid w-auto gap-1 md:grid-cols-3">
                                <li>                                    
                                    <input type="radio" id={`role-admin`} name="lib_role_id" value={1}  className="hidden peer" defaultChecked onClick={handleChange} required />
                                    <label htmlFor={`role-admin`} 
                                    className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                        <div className="block">
                                            <div className="w-full text-sm font-bold truncate">Admin</div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id={`role-organizer`} name="lib_role_id" value={2}  className="hidden peer"  onClick={handleChange} required/>
                                    <label htmlFor={`role-organizer`} 
                                    className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                        <div className="block">
                                            <div className="w-full text-sm font-bold truncate">Organizer</div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id={`role-representative`} name="lib_role_id" value={3}  className="hidden peer" onClick={handleChange}  required />
                                    <label htmlFor={`role-representative`} 
                                    className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                        <div className="block">
                                            <div className="w-full text-sm font-bold truncate">College Representative</div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <label className='text-md font-semibold'>Basic Information</label>
                        <div className='  flex'>
                            <div className=' flex-1 mr-2'>
                                <label className='text-sm'>First Name</label>
                                <input type='text' placeholder='Ex. John'
                                        name='first_name' onChange={handleChange} value={formData.info.first_name}
                                        className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                            </div>
                            <div className=' flex-1 mr-2'>
                                <label className='text-sm'>Last Name</label>
                                <input type='text' placeholder='Ex. Ramos'
                                        name='last_name' onChange={handleChange} value={formData.info.last_name}
                                        className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                            </div>
                            <div className=' flex-1'>
                                <label className='text-sm'>Middle Name</label>
                                <input type='text' placeholder='Ex. Cruz'
                                        name='middle_name' onChange={handleChange} value={formData.info.middle_name}
                                        className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                            </div>
                            
                        </div>
                        <div className='  flex'>
                            <div className='flex-none mr-2'>
                                <label className='text-sm'>Gender</label>
                                <ul className="grid w-auto gap-1 md:grid-cols-2">
                                    <li>
                                        <input type="radio" id={`gender-male`} name="lib_gender_id" value={1}  className="hidden peer" defaultChecked onClick={handleChange} required />
                                        <label htmlFor={`gender-male`} 
                                        className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                            <div className="block">
                                                <div className="w-full text-sm font-bold truncate">Male</div>
                                            </div>
                                        </label>
                                    </li>
                                    <li>
                                        <input type="radio" id={`gender-female`} name="lib_gender_id" value={2}  className="hidden peer" onClick={handleChange}  required />
                                        <label htmlFor={`gender-female`} 
                                        className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                            <div className="block">
                                                <div className="w-full text-sm font-bold truncate">Female</div>
                                            </div>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className=' flex-1'>
                                <label className='text-sm'>Phone No</label> 
                                <input type='number' placeholder='Ex. 09748262912'
                                        name='phone_no' onChange={handleChange} value={formData.info.phone_no}
                                        className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                                </div>
                            
                        </div>
                        <button type='submit' className='mt-5 bg-prc rounded-md py-2 w-full text-white'>Update</button>
                    </form>

                </div>
            )}
        </div>
    )
}
