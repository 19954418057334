import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom'
import { CRUD, CRUDSport, SelectTeam } from '../../Components/Cards'
import ErrorPage from '../../Components/ErrorPage';
import axios from 'axios';
import { v1Basic } from '../../Resource/api';
import { Box, Modal } from '@mui/material';
import { info } from '@iconify-json/bi/index.js';
import { AppContext } from '../../Context/AppContext';
import Loading from '../../Components/Loading';

export default function TeamOutlet({file}) {
  const {token,role} = useContext(AppContext)
  const {id} = useParams()
  const navigate = useNavigate()
  const [isTeam, setIsTeam] = useState(false);
  const [isSport, setIsSport] = useState(false);
  const [apiResponse ,setApiRespnse] =useState()
  const [loading ,setLoading] =useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);


  const requirementMessage = {
    sport_name: 'Sports Name is required',
    venue: 'Venue is required',
    logo: 'Logo is required'
  }

  const [college, setCollege] = useState([]);
  const [sport, setSports] = useState([]);
  const [team, setTeam] = useState([]);
  const [collegeForm, setCollegeForm] = useState({
    title: '',
    acronym: ''
  })

  const [sportsForm, setSportsForm] = useState({
    title: '',
    venue: '',
    gender: ''
  })

  const [teamForm, setTeamForm] = useState({
    team: {
      title: '',
      sports_id: '',
      college_id: id,
      lib_award_id: "1",
      coach_id: "1",
      logo: ''
    },
    info: {
      first_name: '',
      middle_name: '',
      last_name: '',
      phone_no: '',
      lib_gender_id: '',
    }
  })

  const [plainText, setPlainText] = useState({
    college_id: '',
    sports_id: ''
  })

  
  const apiCollege = v1Basic.concat("college")
  const apiSport = v1Basic.concat("sports")
  const apiTeam = v1Basic.concat(`college-team/${id}`)
  const apiTeamPost = v1Basic.concat(`team`)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const handleModal = () => setModalIsOpen(!modalIsOpen)
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(isSubmitting) return
    console.log('sending')
    setLoading(true)
    setIsSubmitting(true)
    try{

      const formData = new FormData();
      if(isSport){

        for (const key in sportsForm) {
          formData.append(`${key}`, sportsForm[key]);
        }

        const response = await axios.post(apiSport, sportsForm, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        console.log('Data Res', response);
        
        // console.log('Response:', response.data);
      }else if(isTeam){
         // Create a new FormData object
        // Append all fields from teamForm and info
        for (const key in teamForm.team) {
          formData.append(`team[${key}]`, teamForm.team[key]);
        }
        for (const key in teamForm.info) {
          formData.append(`info[${key}]`, teamForm.info[key]);
        }
        
        try{
          const response = await axios.post(apiTeamPost, teamForm, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          
          console.log(teamForm)
          handleModal()
          navigate(0)
        } catch (error) {
          setApiRespnse(error.response.data.errors)
          console.error(error.response.data.errors)
        }
      }
      else {

        for (const key in collegeForm) {
          formData.append(`${key}`, collegeForm[key]);
        }
        const response = await axios.post(apiCollege, collegeForm, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        console.log('Response:', response.data);
      }
      // handleModal()
    } catch (error) {
      console.error(error)
      setApiRespnse(error.response.data.errors)
    } finally {
      setIsSubmitting(false)
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    const {name, value, files} = e.target
    if(name == 'logo'){
      const file = files[0]
      setCollegeForm({
        ...sportsForm,
        [name]: file
      })
    }else{
      setCollegeForm({
        ...collegeForm,
        [name]: value
      })
    }
  
  }

  const handleSportsChange = (e) => {
    const {name, value, files} = e.target
    if( name == 'logo'){
      const file = files[0]
      setSportsForm({
        ...sportsForm,
        [name]: file
      })
    }else{
      setSportsForm({
        ...sportsForm,
        [name]: value
      })
    }
  }

  const handleCollegeSelectionChange = (e) => {
    const { name, value, files } = e.target;
  
    if (name === "college_id" || name === "sports_id") {
      const selected = JSON.parse(value);
      const id = selected.id + "";
  
      setTeamForm((prevForm) => ({
        ...prevForm,
        team: {
          ...prevForm.team,  // Spread existing fields in the team object
          [name]: id
        }
      }))
      setPlainText({
        ...plainText,
        [name]: selected.title
      })
    } else if (name == "title"){
      setTeamForm((prevForm) => ({
        ...prevForm,
        team: {
          ...prevForm.team,  // Spread existing fields in the team object
          [name]: value
        }
      }));
    }else if(name == "logo"){
      const file = files[0]
      setTeamForm((prevForm) => ({
        ...prevForm,
        team: {
          ...prevForm.team,  // Spread existing fields in the team object
          [name]: file
        }
      }));
    } else {
      setTeamForm((prevForm) => ({
        ...prevForm,
        info: {
          ...prevForm.info,
          [name]: value
        }
      }));
    }
  }


  useEffect(() => { 
    axios.get(apiTeam).then((res) => {
      setTeam(res.data.data)
    })
    axios.get(apiCollege).then((res) => {
      setCollege(res.data.data)
    })
    axios.get(apiSport).then((res) => {
      setSports(res.data.data)
    })
    if(file == "team"){
      setIsTeam(true);
      setIsSport(false)

    } else if (file == "Sport"){
      setIsSport(true)
      setIsTeam(false);
    }else{
      setIsTeam(false)
      
      setIsSport(false)
    }
  },[file])

  const [searchData, setSearchData]= useState({
    type: file,
    term: '',
    team_id: null,
    college_id: id,
  })

  const handleSearch = async () => {
    try{
      setLoading(true)
      const response = await axios.put(v1Basic.concat('search'),searchData, {
        headers: {
          'Content-type': 'application/json'
        }
      })
      setTeam(response.data)
      // console.log(response)
    } catch (error) {

    }finally {

      setLoading(false)
    }
  }

  const handleTerm = (e) => {
    const {name, value} =e.target
    setSearchData({
      ...searchData,
      [name]: value
    })
  } 

  if(token) {

        return (
            <>
            {loading && <Loading />}
            <div className='bg-cont flex-col flex m-2 p-3 rounded-lg'>
                <div className='flex-1 mb-8'>
                <div className='flex'>
                    <div className=' flex-1 '>
                    <div className=' flex'>
                        <input name='term' type='text' onKeyDown={(e) => e.key === 'Enter' && handleSearch(e)} onChange={handleTerm} placeholder={`Find ${file}...`} 
                            className='bg-transparent p-2 border-2 rounded-l-md flex-1' />
                        <div className='p-3 flex-none bg-prc rounded-r-md' onClick={handleSearch}>
                        <span className='icon-[majesticons--search] bg-white w-5 h-5 mt-2 mx-2'></span>
                        </div>
                        <div onClick={handleModal} className='cursor-pointer px-10 ml-20 text-center content-center flex-none bg-prc text-white rounded-md'>
                        Add {file}
                        </div>
                        
                        {isTeam ? (
                        <Modal open={modalIsOpen} onClose={handleModal}
                        aria-labelledby="modal-title" aria-describedby="modal-description"
                        className="flex justify-center items-center h-full overflow-auto"
                        >
                        <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 w-2/3">
                            {loading && <Loading />}
                        
                            <h3 id="modal-title" className='font-semibold text-xl'>New Team</h3>
                            <p id="modal-description " className='mb-4'>This Popup is for adding a new {file}.</p>
                            <form onSubmit={handleSubmit}>
                                <label className='text-sm'>Select Logo</label>
                                <br></br>
                                <input type="file" name='logo' required onChange={handleCollegeSelectionChange} accept="image/png" className='mb-2  w-full rounded-md p-2 bg-def-t bg-opacity-5 cursor-pointer'/>
                                {apiResponse?.['team.logo'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['team.logo']}</div>)}
                                <label className=' text-sm'>Team Name</label>
                                <br></br>
                                <input type='text' placeholder='Ex. SKT T1' required
                                        name='title' onChange={handleCollegeSelectionChange}
                                        className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                                {apiResponse?.['team.title'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['team.title']}</div>)}

                                {/* colleges */}
                                {/* <div className='mb-4'> 
                                <label className='text-sm'>Select College</label>
                                <h3 className="mb-2 text-lg font-medium text-def-t">{plainText.college_id}</h3>
                                <ul className="grid w-full gap-1 md:grid-cols-11">
                                    {college.map((item, index) => (
                                    <li key={index}>
                                        <input type="radio"  id={`${item.acronym}-${item.id}`} name="college_id" value={JSON.stringify({ id: item.id, title: item.title })}  className="hidden peer" onClick={handleCollegeSelectionChange} required />
                                        <label htmlFor={`${item.acronym}-${item.id}`} 
                                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                            <div className="block">
                                                <div className="w-full text-sm font-bold">{item.acronym}</div>
                                            </div>
                                        </label>
                                    </li>
                                    ))}
                                </ul>
                                {apiResponse?.['team.college_id'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['team.college_id']}</div>)}
                                </div> */}
                                {/* sports */}
                                <div> 
                                <label className='text-sm'>Select Sports</label>
                                <h3 className="mb-2 text-lg font-medium text-def-t">{plainText.sports_id}</h3>
                                <ul className="grid w-full gap-1 md:grid-cols-6">
                                    {sport.map((item, index) => (
                                    <li key={index}>
                                        <input type="radio" id={`${item.acronym}-${item.id}`} name="sports_id" value={JSON.stringify({ id: item.id, title: item.title })}  className="hidden peer" onClick={handleCollegeSelectionChange} required />
                                        <label htmlFor={`${item.acronym}-${item.id}`} 
                                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                            <div className="block">
                                                <div className="w-full text-sm font-bold truncate">{item.title}</div>
                                                
                                            </div>
                                        </label>
                                    </li>
                                    ))}
                                </ul>
                                {apiResponse?.['team.sports_id'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['team.sports_id']}</div>)}

                                </div>
                                <h3 className="mt-4 text-lg font-medium text-gray-900">Coach Information</h3>
                                <div className=' flex flex-col '>
                                    <div className=' flex-1'>
                                    <div className=' flex'>
                                        <div className='flex-1 mr-2'>
                                        <label className='text-sm'>First Name</label>
                                        <input type='text' placeholder='Ex. John'
                                        name='first_name' onChange={handleCollegeSelectionChange}
                                        className=' w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                                        {apiResponse?.['info.first_name'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['info.first_name']}</div>)}
                                        </div>

                                        <div className='flex-1 mr-2'>
                                        <label className='text-sm'>Last Name</label>
                                        <input type='text' placeholder='Ex. Ramos'
                                        name='last_name' onChange={handleCollegeSelectionChange}
                                        className=' w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                                        {apiResponse?.['info.last_name'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['info.last_name']}</div>)}
                                    
                                        </div>
                                        <div className='flex-1'>
                                        <label className='text-sm'>Middle Name</label>
                                        <input type='text' placeholder='Ex. Pascual'
                                        name='middle_name' onChange={handleCollegeSelectionChange}
                                        className=' w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                                        {apiResponse?.['info.middle_name'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['info.middle_name']}</div>)}
                                        
                                        </div>
                                    </div>
                                    </div>
                                    <div className=' flex-1'>
                                    <div className=' flex'>
                                        <div className='flex-none mr-2'>
                                        <label className='text-sm'>Gender</label>
                                        <ul className="grid w-auto gap-1 md:grid-cols-2">
                                            <li>
                                                <input type="radio" id={`gender-male`} name="lib_gender_id" value={1}  className="hidden peer" onClick={handleCollegeSelectionChange} required/>
                                                <label htmlFor={`gender-male`} 
                                                    className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                                    <div className="block">
                                                        <div className="w-full text-sm font-bold truncate">Male</div>
                                                    </div>
                                                </label>
                                            </li>
                                            <li>
                                                <input type="radio" id={`gender-female`} name="lib_gender_id" value={2}  className="hidden peer" onClick={handleCollegeSelectionChange} required />
                                                <label htmlFor={`gender-female`} 
                                                    className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                                    <div className="block">
                                                        <div className="w-full text-sm font-bold truncate">Female</div>
                                                    </div>
                                                </label>
                                            </li>
                                        </ul>
                                        {apiResponse?.['info.lib_gender_id'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['info.lib_gender_id']}</div>)}
                                        </div>
                                        <div className='flex-1'>
                                        <label className='text-sm'>Phone No.</label>
                                        <input type='number' placeholder='Ex. 09217563948'
                                        name='phone_no' onChange={handleCollegeSelectionChange}
                                        className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                                        {apiResponse?.['info.phone_no'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['info.phone_no']}</div>)}
                                        </div>
                                        
                                    </div>
                                    </div>
                                </div>
                            <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Add {file}</button>
                            </form>
                        </Box>
                        </Modal>
                        ):(

                        <Modal open={modalIsOpen} onClose={handleModal}
                        aria-labelledby="modal-title" aria-describedby="modal-description"
                        className="flex justify-center items-center h-screen"
                        >
                        <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
                            {loading && <Loading />}

                            <h3 id="modal-title" className='font-semibold text-xl'>New {file}</h3>
                            <p id="modal-description " className='mb-4'>This Popup is for adding a new {file}.</p>
                            <form onSubmit={handleSubmit}>
                            
                            {isSport ? (
                                <>
                            {loading && <Loading />}

                                <label className='text-sm'>Select Logo</label>
                                <br></br>
                                <input type="file" name='logo' onChange={handleSportsChange} accept="image/png" className='mb-2  w-full rounded-md p-2 bg-def-t bg-opacity-5 cursor-pointer'/>
                                {apiResponse?.logo &&(
                                <>
                                {apiResponse?.logo.map((item, index) => (
                                    <div key={index} className='text-sm text-red-800 text-opacity-60'>{item}</div>
                                ))}
                                </>
                                )}
                                <br></br>
                                <label className='text-sm'>{file} Name</label>
                                <br></br>
                                <input type='text' placeholder='Ex. Basketball'
                                        name='title' onChange={handleSportsChange}
                                        className='w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                                {apiResponse?.title &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.title}</div>)}
                                {/* <br ></br> */}
                                <label className='text-sm'>Venue</label>
                                <br></br>
                                <input  type='text' placeholder='Ex. TSU San Isidro Campus'
                                name='venue' onChange={handleSportsChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                                {apiResponse?.venue &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.venue}</div>)}

                                </>
                                
                            ) : (
                                <>
                                <label className='text-sm'>Select Logo</label>
                                <br></br>
                                <input type="file" name='logo' onChange={handleChange} accept="image/png" className='mb-2  w-full rounded-md p-2 bg-def-t bg-opacity-5 cursor-pointer'/>
                                {apiResponse?.logo &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.logo}</div>)}
                                <label className='text-sm'>{file} Name</label>
                                <br></br>
                                <input type='text' placeholder='Ex. College of Computer Studies'
                                        name='title' onChange={handleChange}
                                        className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                                {apiResponse?.title &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.title}</div>)}
                                <label className='text-sm'>Acronym</label>
                                <br></br>
                                <input  type='text' placeholder='Ex. CCS'
                                name='acronym' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                                {apiResponse?.acronym &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.acronym}</div>)}
                                
                                </>
                                
                            )}
                            <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Add {file}</button>
                            </form>
                        </Box>
                        </Modal>
                        )}
                    </div>
                    </div>
                </div>
                </div>
                
                {isTeam ? (
                    <>
                    {!team || team.length == 0 ? (
                    <div className='w-full h-screen text-prc content-center justify-center text-center font-extralight text-4xl'>No Scheduled Match</div>
                    ):(
                    <>
                        {team.map((item, index) => (
                        <SelectTeam data={item} teamID={item.id} key={index} />
                        ))}
                    </>
                    )}
                    </>
                ) : isSport ? (
                <>
                    {!sport || sport.length == 0 ? (
                    <div className='w-full h-screen text-prc content-center justify-center text-center font-extralight text-4xl'>No Scheduled Match</div>
                    ):(
                    <>
                    {sport.map((item, index) => (
                        <CRUDSport data={item} key={index} api={apiSport}/>
                        // <div> hee</div>
                    ))}
                    </>
                    )}
                </>
                ) : (
                <>
                    {!college || college.length == 0 ? (
                    <div className='w-full h-screen text-prc content-center justify-center text-center font-extralight text-4xl'>No Scheduled Match</div>
                    ):(
                    <>
                        {college.map((item, index)=> (
                        <CRUD data={item} key={index} api={apiCollege}/>
                        ))}
                    </>
                    )}
                </>
                )}
            </div>
            </>
        )
    } else {
        return (
            <Navigate to={'/login'} replace={true}/>
        )
    }

}
