import React from 'react';
import '../index.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import HomePage, { ResultPage, SchedulePage } from '../Page/Public/Index';
import ErrorPage from '../Components/ErrorPage';
import PublicTopBar from '../Components/TopBar';
import Login from '../Page/Login';
import Index from '../Page/Admin/Index';
import MasterFile from '../Page/Admin/MasterFile/File';
import Home from '../Page/Admin/MasterFile/Homes';
import Team from '../Page/Admin/MasterFile/Team';
import Bracketing from '../Page/Admin/Schedule/Bracketing';
import ListOfBanner from '../Page/Admin/Banners/ListOfBanner';
import UserManagement from '../Page/Admin/Account/UserManagement';
import Dashboard, { DashboardIndex } from '../Page/Admin/Dashboard/Dashboard';
import Position from '../Page/Admin/MasterFile/Position';
import Result from '../Page/Admin/Schedule/Result';
import MyProfile from '../Components/MyProfile';
import IncidentBoard from '../Page/Admin/Dashboard/IncidentBoard';
import ReportPage from '../Page/Public/ReportPage';
import { SportsSchedulePage } from '../Page/Public/Schedule/SportSchedule';
import TeamOutlet from '../V2/Outlet/TeamOutlet';

// Define your routes
export const router = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <PublicTopBar />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "result",
        element: <ResultPage />,
      },
      {
        path: "report",
        element: <ReportPage />,
      },
      {
        path: "schedule",
        element: <SchedulePage loc={'/schedule'} />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Navigate to="1" replace />,
          },
          {
            path: ":sportID",
            element: <SportsSchedulePage />,
            errorElement: <ErrorPage />,
          },
        ],
      },
    ],
  },
  {
    path: "admin",
    element: <Index />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="public/home" replace />,
      },
      {
        path: "public",
        element: <Home />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Navigate to="home" replace />,
          },
          {
            path: "home",
            element: <HomePage />,
            errorElement: <ErrorPage />,
          },
          {
            path: "report",
            element: <ReportPage />,
          },
          {
            path: "result",
            element: <ResultPage />,
            errorElement: <ErrorPage />,
          },
          {
            path: "schedule",
            element: <SchedulePage loc={'/admin/public/schedule'} />,
            errorElement: <ErrorPage />,
            children: [
              {
                index: true,
                element: <Navigate to="1" replace />,
              },
              {
                path: ":sportID",
                element: <SportsSchedulePage />,
                errorElement: <ErrorPage />,
              },
            ],
          },
        ],
      },
      {
        path: "master-file",
        element: <Home />,
        errorElement: <ErrorPage />,
        children:
        [
          {
            index: true,
            element: <Navigate to="college" replace/>
          },
          {
            path: "college",
            element: <MasterFile file={"College"}/>,
            errorElement: <ErrorPage/>
          },
          {
            path: "college/:id",
            element: <TeamOutlet file={"team"}/>,
            errorElement: <ErrorPage/>
          },
          {
            path: "college/:id/:teamID",
            element: <Team />,
            errorElement: <ErrorPage />,
          },
          {
            path: "sports",
            element: <MasterFile file={"Sport"}/>,
            errorElement: <ErrorPage/>
          },
          {
            path: "sports/:sportsID",
            element: <Position />,
            errorElement: <ErrorPage />,
          },
          {
            path: "team",
            element: <MasterFile file={"team"}/>,
            errorElement: <ErrorPage/>
          },              
          {
            path: "team/:teamID",
            element: <Team />,
            errorElement: <ErrorPage />,
          },
        ]
      },
      {
        path: "sched",
        element: <Home />,
        errorElement: <ErrorPage />,
        children: 
        [
          {
            index: true,
            element: <Navigate to="schedule" replace/>
          },
          {
            path: "schedule",
            element: <SchedulePage loc={'/admin/sched/schedule'} />,
            errorElement: <ErrorPage />,
            children: [
              {
                index: true,
                element: <Navigate to="1" replace />,
              },
              {
                path: ":sportID",
                element: <Bracketing />,
                errorElement: <ErrorPage />,
              },
            ],
          },     
          {
            path: "result",
            element: <SchedulePage loc={'/admin/sched/result'} />,
            errorElement: <ErrorPage />,
            children: [
              {
                index: true,
                element: <Navigate to="1" replace />,
              },
              {
                path: ":sportID",
                element: <Result />,
                errorElement: <ErrorPage />,
              },
            ],
          },       
        ]
      },
      {
        path: "banner",
        element: <ListOfBanner/>,
        errorElement: <ErrorPage />
      },
      {
        path: "account",
        element: <UserManagement/>,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Navigate to="users" replace />
          },
          {
            path: "users",
            element: <UserManagement />,
            errorElement: <ErrorPage />
          }
          
        ]
      },
      {
        path: "account/:accountID",
        element: <MyProfile isMyAccount={false} />,
        errorElement: <ErrorPage />
      },
      {
        path: "sum",
        element: <DashboardIndex />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Navigate to="dashboard" replace />
          },
          {
            path: "dashboard",
            element: <Dashboard />,
            errorElement: <ErrorPage />
          },
          {
            path: "incident",
            element: <IncidentBoard />,
            errorElement: <ErrorPage />
          }
          
        ]
      },
      {
        path: "profile",
        element: <MyProfile isMyAccount={true} />,
        errorElement: <ErrorPage />
      }
    ],
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

export const local = "http://localhost:8000/api";
export const prod = "https://api.sportstournamentmanagement.com/api";
export const baseUrl = prod
export const v1Basic = baseUrl + "/v1/basic-controller/";
