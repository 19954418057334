import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { v1Basic } from '../../../Resource/api';
import axios from 'axios';
import { CRUDPos } from '../../../Components/Cards';
import { AppBar, Box, Modal } from '@mui/material';
import { AppContext } from '../../../Context/AppContext';

export default function Position() {
    const {sportsID } = useParams();
    const {token ,role} = useContext(AppContext)
    const [sports, setSports] = useState([])

    const apiPos = v1Basic.concat(`position`)
    const api = v1Basic.concat(`sports/${sportsID}`)

    const handleChange = (e) => {
        const {name, value} = e.target
        setPositionForm({
            ...positionForm,
            [name]: value
        })
    }
    
    const [positionForm, setPositionForm] = useState({
        desc: '',
        sports_id: sportsID
    })
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try{
          const response = await axios.post(apiPos, positionForm, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
            handleAddModal()

        //   console.log('Response:', response.data);
        } catch (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Error Response Data:', error.response.data);
              console.error('Error Response Status:', error.response.status);
              console.error('Error Response Headers:', error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.error('Error Request:', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error Message:', error.message);
            }
        }
        }

      
    const [addModalIsOpen, setAddModalIsOpen] = useState(false)
    const handleAddModal = () => setAddModalIsOpen(!addModalIsOpen)


    useEffect(() => {
        axios.get(api).then((res)=> {
            setSports(res.data.data)
        })
        // console.log(sports)
    },[])

    const position = sports?.position || []
    return (
    <div className='m-2 mt-5 flex'>
    {token==null && (<Navigate to={'/login'} replace={true}/>)}
    {role == 'College Representative' && (<Navigate to={'/'} replace={true}/>)}

            <Modal open={addModalIsOpen} onClose={handleAddModal}
                aria-labelledby="modal-title" aria-describedby="modal-description"
                className="flex justify-center items-center h-screen"
            >
                <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
                <h3 id="modal-title" className='font-semibold text-xl'>New Position</h3>
                <p id="modal-description " className='mb-4'>This Popup is for new position.</p>
                <form onSubmit={handleSubmit}>
                    <label className='text-sm'>Position Name</label>
                    <br></br>
                    <input type='text' placeholder='Ex. Libero' required
                            name='desc' onChange={handleChange} value={positionForm.desc}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    
                    <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Add Position</button>
                </form>
                </Box>
            </Modal>
            <div className='flex-1'>
                <div className='bg-cont flex-col flex   rounded-lg'>
                    <div className='flex-1 m-3 mb-3'>
                        <div className='flex'>
                        <div className=' flex-1 '>
                            <div className=' flex'>
                                <div className=' flex-1 font-bold text-prc text-3xl content-center'>{sports.title}</div>
                                <div className=' px-10 py-4 ml-20 text-center content-center flex-none bg-prc text-white rounded-md cursor-pointer' onClick={handleAddModal}>
                                    Add Position
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {!position || position.length == 0 ? (
                        <div className='w-full h-screen text-prc content-center justify-center text-center font-extralight text-4xl'>No Position</div>
                    ):(
                        <>
                            <table>
                                <thead className='bg-prc text-white text-left'>
                                    <tr>
                                        <th className='px-6 py-3'>Position</th>
                                        <th className='px-6 py-3'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {position.map((item, index) => (
                                        <CRUDPos desc={item.desc} id={item.id} key={index} sportsID={sportsID}/>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div> 
            </div>
        </div>
    ) 
}


